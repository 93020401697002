.initialCookies {
    padding: 24px;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.initialCookiesTitle {
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    margin-bottom: 12px;
}

.initialCookiesLower {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.initialCookiesText {
    font-size: 16px;
}

.buttonsContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.acceptButton {
    height: 36px;
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1.4px solid transparent;
    color: black;
    font-weight: 500;
    transition: 0.5s ease;
}

.acceptButton:hover {
    transition: 0.5s ease;
    background-color: transparent;
    border: 1.4px solid white;
    color: white;
}

@media screen and (max-width: 800px) {
    .initialCookiesLower {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .buttonsContainer {
        margin-top: 24px;
        width: 100%;
    }

    .manageButton {
        margin-left: 0;
    }
}