.gallery {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--black);
    position: relative;
}

.body {
    min-height: calc(100vh - 100px);
    width: 100%;
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.title {
    font-size: 44px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.line {
    height: 40px;
    width: 2px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 48px;
}

.images {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 400px));
    gap: 24px;
    padding: 24px;
    justify-content: center;
    align-content: center;
}  

.imageContainer {
    min-height: 400px;
    max-height: 400px;
    max-width: 400px;
    height: 400px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
}