.menus {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--black);
}

.title {
    margin-top: 80px;
    font-size: 44px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.line {
    height: 40px;
    width: 2px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 36px;
}

.options {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 48px;
}

.option {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 2px;
    transition: 0.5s ease;
}

.selected {
    transition: 0.5s ease;
    border-bottom: 2px solid white;
}

.body {
    min-height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px;
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 350px);
    gap: 24px;
}

.gridImageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.gridImage {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    z-index: 0;
    filter: brightness(80%);
    transition: 0.5s ease;
    user-select: none;
}

.gridBlock:hover .gridImage {
    transition: 0.5s ease;
    filter: none;
    background-image: none;
}

.gridBlock1:hover .gridImage {
    transition: 0.5s ease;
    filter: none;
    background-image: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to top, #000000db 0%, rgba(16,18,19,0) 60%);
}

.gridBlock {
    height: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 40px;
}

.gridBlock1 {
    height: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    grid-column: span 2;
    cursor: pointer;
    padding: 40px;
}

.gridBlockTitle {
    font-size: 22px;
    font-weight: 500;
    z-index: 1;
    margin-bottom: 24px;
    text-align: center;
}

.gridBlockSubtitle {
    font-size: 18px;
    font-weight: 300;
    z-index: 1;
    color: var(--whiteSubtext);
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .body {
        padding: 80px 24px;
    }

    .grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 350px);
    }

    .gridBlock {
        grid-column: auto;
        order: 1;
    }

    .gridBlock1 {
        grid-column: auto;
        order: -1;
    }
}