.footer {
    min-height: 100px;
    height: 100px;
    max-height: 100px;
    width: 100%;
    padding: 18px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--black);
}

.footerLeft {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footerMid {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerRight {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footerText {
    font-size: 16px;
    color: white;
    padding: 12px 0;
    text-align: center;
}

.footerRightText {
    font-size: 16px;
    color: white;
    padding: 8px 0;
    cursor: pointer;
    margin-left: 12px;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .footer {
        min-height: 100px;
        height: auto;
        max-height: none;
        flex-direction: column;
    }

    .footerLeft {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        order: 1;
    }

    .footerMid {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        order: 0;
    }

    .footerRight {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        order: 2;
    }
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 18px 24px;
    }
}