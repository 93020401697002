.reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
    padding: 80px 120px;
}

.review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
}

.text {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: var(--whiteSubtext);
}

.nameContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
}

.stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    column-gap: 4px;
}

.name {
    font-size: 20px;
    text-align: center;
}

.buttonLeft {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 54px;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;
    z-index: 2;
}

.buttonRight {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    right: 54px;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;
    z-index: 2;
}

.mobileNav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    column-gap: 24px;
    display: none;
}

.buttonLeftMobile {
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    z-index: 2;
}

.buttonRightMobile {
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    z-index: 2;
}

@media screen and (max-width: 1024px) {
    .mobileNav {
        display: flex;
    }

    .buttonLeft {
        display: none;
    }

    .buttonRight {
        display: none;
    }

    .reviews {
        padding: 80px 24px;
    }

    .nameContainer {
        flex-direction: column;
    }

    .name {
        margin-top: 24px;
    }
}