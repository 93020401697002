.located {
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
}

.right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 18px;
    text-align: center;
}

.title {
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
}

.desc {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    color: var(--whiteSubtext);
}

.button {
    margin-top: 36px;
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.button:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

@media screen and (max-width: 1024px) {
    .located {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        background-color: black;
        padding: 80px 24px;
    }

    .right {
        padding: 12px;
        padding-top: 0;
        margin-bottom: 48px;
        width: 100%;
    }

    .left {
        width: 100%;
    }
}