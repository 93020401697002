.about {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    padding-bottom: 20px;
}

.title {
    font-size: 44px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.line {
    height: 40px;
    width: 2px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 48px;
}

.text {
    font-size: 24px;
    color: var(--whiteSubtext);
    text-align: center;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .about {
        padding: 24px;
        padding-top: 80px;
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 500px) {
    .text {
        font-size: 20px;
    }
}