.cta {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    padding-top: 0;
}

.title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
}

.subtitle {
    font-size: 20px;
    margin-bottom: 36px;
    text-align: center;
}

.button {
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    border: 2px solid white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.button:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

@media screen and (max-width: 1024px) {
    .cta {
        padding: 80px 24px;
        padding-top: 0;
    }
}