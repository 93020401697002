.contact {
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}


.right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
}

.title {
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 48px;
    text-align: center;
    text-align: center;
}

.desc {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    color: var(--whiteSubtext);
    text-align: center;
}

.button {
    margin-top: 36px;
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
}

.button:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 18px;
    text-align: center;
    margin-bottom: 24px;
}

.text {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    margin-top: 24px;
}

.call {
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.call:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.directions {
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    border: 2px solid white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.directions:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

@media screen and (max-width: 1024px) {
    .contact {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        padding: 80px 24px;
    }

    .right {
        width: 100%;
    }

    .left {
        padding: 12px;
        padding-top: 0;
        margin-bottom: 48px;
        width: 100%;
    }

    .buttonsContainer {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .call {
        margin-bottom: 18px;
        width: 250px;
    }

    .directions {
        width: 250px;
    }
}