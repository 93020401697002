.hero {
    height: 700px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    background-color: var(--black);
    padding: 24px;
}

.heroBackgroundContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 700px;
    max-height: 700px;
    width: 100%;
    z-index: -1;
    user-select: none;
}

.heroBackground {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    filter: brightness(80%) saturate(130%);
}

.title {
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    margin-bottom: 18px;
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 40px black;
}

.subtitle {
    font-size: 54px;
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    letter-spacing: 8px;
    text-align: center;
    text-shadow: 0 0 40px black;
}

.cta {
    margin-top: 24px;
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cta:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.heroLeft {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hours {
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    row-gap: 8px;
}

.option {
    font-size: 16px;
    font-weight: 400;
    color: var(--whiteSubtext);
    text-shadow: 0 0 20px black;
}