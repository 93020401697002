.header {
    min-height: 80px;
    height: 80px;
    max-height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 12px 48px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 48px;
    z-index: 98;
}

.center {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logoText {
    font-size: 22px;
    letter-spacing: 2px;
}

.right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 48px;
    z-index: 98;
}

.navText {
    font-size: 12px;
    letter-spacing: 2px;
}

.navButton {
    font-size: 12px;
    letter-spacing: 2px;
    padding: 10px 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    border: 2px solid white;
    transition: 0.5s ease;
}

.navButton:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

.mobileNav {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    align-items: center;
    position: relative;
    z-index: 99;
    display: none;
}

.mobileLogo {
    font-size: 24px;
}

.mobileMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.mobileMenuText {
    padding: 20px 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: 0.5s ease;
    font-weight: 500;
    background-color: black;
}

.mobileMenuText:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

.mobileMenuButton {
    padding: 20px 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: 0.5s ease;
    background-color: white;
    color: black;
    font-weight: 500;
}

.mobileMenuButton:hover {
    transition: 0.5s ease;
    background-color: black;
    color: white;
}

.backdrop {
    min-height: calc(100vh - 80px);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--backdrop);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    margin-top: 80px;
}

@media screen and (max-width: 1024px) {
    .left, .center, .right{
        display: none;
    }

    .mobileNav {
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    .header {
        padding: 12px 24px;
    }
}